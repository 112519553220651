<template>
    <div :class="$store.getters.getSort(tag)" v-loading="loading" :element-loading-text="`${$t('正在上传,请稍候')}...`">
        <div class="flex flex-h-center">
            <div class="center padding" style="background-color: #f0f0f0; border-radius: 20px;width: 360px;">
                <h3>Audio Recorder</h3>
                <div class="margin-t center st1" v-if="status == 0">Recording in {{ time }}</div>

                <div class="margin-t st1 center" v-else-if="status == 1">Click to record
                </div>

                <div class="margin-t st1 center" v-else-if="status == 2">Recording
                </div>
                <div class="margin-t st1 center" v-else-if="status == 3 || status == 4">Recording Finished</div>
                <div class="margin-t st1 center" v-else>&nbsp;</div>
                <div class="flex flex-v-center time">
                    <el-slider class="flex1 margin-l" v-if="src" :step="1" @change="changeTime" v-model="time"
                        :max="duration"></el-slider>
                    <el-progress v-else :show-text="false" class="flex1 margin-l"
                        :percentage="percentage"></el-progress>
                    <div v-if="src" class="margin-x st1">{{ format_time1(duration) }}</div>
                    <div v-else class="margin-x st1">{{ format_time(time) }}</div>
                    <!-- <canvas v-if="recording" id="canvas" width="360px" height="80px" class="canvas"></canvas>
                  <div v-else style="background:#E6A23C;height:2px;width: 360px;margin:54px 0 43px;"></div> -->
                </div>

                <div v-if="src" class="flex flex-h-center margin-t" style="height:32px;">
                    <el-button v-if="!isImmersive" type="primary" @click="playAudio" size="mini">{{ playing ? $t('暂停') : $t('播放')
                        }}</el-button>
                    <el-button :disabled="submited" :type="submited ? 'info' : 'primary'" v-if="submittable"
                        @click="submit" class="margin-l" size="mini">{{ $t('提交') }}</el-button>
                    <el-button v-if="!isImmersive" type="primary" @click="download" class="margin-l" size="mini">{{ $t('下载') }}</el-button>
                </div>
                <div v-else class="center margin-t" style="position:relative;">
                    <el-image @click="start(1)" class="pointer icon-player"
                        :src="recording ? '/web/image/Speaking/audio_stop.png' : (status == -1 || status == 1) ? '/web/image/Speaking/audio_start.png' : '/web/image/Speaking/audio_start1.png'"
                        fit="contain"></el-image>
                    <div style="position: absolute; right:5px; top:8px; display: inline-block;">
                        <el-tooltip class="item" effect="dark" :content="autoRecord ? $t('自动录音开启') : $t('自动录音关闭')"
                            placement="top">
                            <el-switch @change="changeRecord" active-color="#E6A23C" v-model="autoRecord"></el-switch>
                        </el-tooltip>
                    </div>
                </div>
                <!-- <div class="st margin-t">点击上方录音按钮跳过预读，直接开始/结束录音</div> -->
            </div>
        </div>

        <audio ref="audio" style="display:none" src="/web/audio/du.mp3?v=1">
            浏览器太老咯，请升级浏览器吧~
        </audio>

        <audio ref="audio1" @ended="onEnd" @loadedmetadata="onLoadedmetadata" @play="onPlay" @pause="onPause" v-if="src"
            style="display:none" :src="src" controls=""></audio>
    </div>
</template>

<script>
import Recorder from 'js-audio-recorder';
import bus from "../common/bus";
// import WavePlayer from './WavePlayer.vue'
export default {
    components: {
        // WavePlayer
    },

    destroyed() {
        this.isDestroyed = true;
        if (this.recording) {
            this.recorder.stop();
        }
    },
    name: "Audio-Recorder",
    data() {
        return {
            isDestroyed: false,
            submited: false,
            loading: false,
            percentage: 0,
            time: 0,
            recording: false,
            src: null,
            drawRecordId: null,
            canvas: null,
            ctx: null,
            recorder: null,
            autoRecord: true,
            status: -1,
            playing: false,
            duration: 0
        };
    },

    watch: {
        // in_time(val) {
        //     // this.time = val;
        // }

    },

    props: {
        time_limit: {
            type: Number,
            default: 10,
        },
        in_time: {
            type: Number,
            default: 10,
        },
        tag: {
            type: String,
            default: "",
        },

        ra_flag: {
            type: Boolean,
            default: false,
        },

        submittable: {
            type: Boolean,
            default: true,
        },
        isImmersive: {
            type: Boolean,
            default: false,
        },
    },


    created() {
        this.autoRecord = localStorage.getItem("autoRecord") == undefined ? true : localStorage.getItem("autoRecord") == 1 ? true : false;
        this.recorder = new Recorder({
            sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
            sampleRate: 16000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
            numChannels: 1,                 // 声道，支持 1 或 2， 默认是1
            // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
        });

        this.timer();

        this.$bus.$on("loading", (loading) => {
            this.loading = loading;
        })
    },
    methods: {
        submit() {
            this.$parent.$parent.$parent.$parent.submitRecord();
            this.submited = true;
        },

        changeTime() {
            this.$refs.audio1.currentTime = this.time
        },

        onLoadedmetadata() {
            this.time = 0;
            this.duration = Math.ceil(this.$refs.audio1.duration);
        },
        onPlay() {
            this.playing = true;
            this.status = 4;
        },
        onPause() {
            this.playing = false;
            this.status = 3;
        },
        onEnd() {
            setTimeout(() => {
                this.time = 0;
                this.status = 3;
            }, 200);

        },
        playAudio() {
            if (this.playing) {
                this.$refs.audio1.pause();
            } else {
                this.$refs.audio1.play();
            }
        },
        download() {
            let a = document.createElement('a');              // 获取文件名fileName
            let fileName = "录音下载_" + new Date().getTime() + ".wav";
            a.download = fileName;
            a.href = this.src;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        changeRecord(val) {
            if (val) {
                localStorage.setItem("autoRecord", 1);
            } else {
                localStorage.setItem("autoRecord", 0);
            }

        },
        start(flag) {
            if (this.isDestroyed) return;
            let that = this;
            this.src = null;
            // this.time = 0;

            this.percentage = 0;

            if (!this.autoRecord) {
                this.status = 1;
            }

            if (this.recording) {
                this.playing = false;
                this.stop();
                return;
            }

            if (!this.autoRecord && !flag) return;

            if (this.tag == "RA" || this.tag == "DI" || this.tag == "RL") {
                if (this.$refs.audio && (this.status == 0 || this.status == 2)) {
                    this.$refs.audio.play();
                }
            }
            setTimeout(() => {
                return new Promise((resolve) => {
                    that.recorder.start().then(() => {
                        that.recording = true;
                        that.status = 2;
                        that.$emit("start");
                        bus.$emit("content", null);
                        this.$emit('timeUpdated', 0)
                        that.time = 0
                        resolve();
                    })
                })
            }, 500);
        },
        stop(flag) {
            if (flag != 1) {
                if (this.recorder.getWAVBlob().size > 100) {
                    this.src = URL.createObjectURL(this.recorder.getWAVBlob());
                    this.$emit("ended", this.src);
                    bus.$emit("content", this.recorder.getWAVBlob());
                } else {
                    return;
                }
            }

            this.recorder.stop();
            this.recording = false;
            this.playing = false;
            this.status = 3;
        },
        refrsh(status) {
            this.submited = false;
            this.stop(1);
            this.src = "";
            this.time = this.in_time;
            if (status != undefined) {
                this.status = status;
            }
            this.percentage = 0;
        },
        format_time1(time) {
            let minute = parseInt(time / 60);
            let second = time - minute * 60;
            return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
        },
        format_time(time) {
            if (this.status <= 0) {
                return "00:00";
            } else if (this.status == 4 || this.status == 3) {
                this.percentage = parseInt(time / this.duration * 100);
                let minute = parseInt(time / 60);
                let second = time - minute * 60;
                return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
            } else {
                if (this.recording) {
                    this.percentage = parseInt(time / this.time_limit * 100);
                    if (this.time == this.time_limit) {
                        this.stop();
                    }
                    let minute = parseInt(time / 60);
                    let second = time - minute * 60;
                    return (minute < 10 ? `0${minute}` : minute) + ":" + (second < 10 ? `0${second}` : second);
                }
                return "00:00";
            }

        },

        timer() {
            let that = this;
            setTimeout(() => {
                if (that.status == 0) {
                    if (that.time > 0) {
                        that.time--;
                        this.$emit('timeUpdated', that.time)
                    }
                    if (that.time == 0) {
                        if (this.autoRecord) {
                            that.status = 2;
                            if (this.ra_flag) {
                                this.status = 1;
                            } else {
                                this.start(1);
                            }
                        } else {
                            that.status = 1;
                        }
                    }
                } else if (that.status == 2) {
                    that.time++;
                } else if (that.status == 4) {
                    that.time++;
                }
                console.log(that.time)
                if (!this.isDestroyed) that.timer();

            }, 1000);
        },

        drawRecord() {
            // 用requestAnimationFrame稳定60fps绘制
            this.drawRecordId = requestAnimationFrame(this.drawRecord);

            // 实时获取音频大小数据
            let dataArray = this.recorder.getRecordAnalyseData(),
                bufferLength = dataArray.length;

            // 填充背景色
            this.ctx.fillStyle = 'rgb(240, 240, 240)';
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

            // 设定波形绘制颜色
            this.ctx.lineWidth = 2;
            this.ctx.strokeStyle = '#E6A23C';

            this.ctx.beginPath();

            var sliceWidth = this.canvas.width * 1.0 / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
                x = 0;          // 绘制点的x轴位置

            for (var i = 0; i < bufferLength; i++) {
                var v = dataArray[i] / 128.0;
                var y = v * this.canvas.height / 2;

                if (i === 0) {
                    // 第一个点
                    this.ctx.moveTo(x, y);
                } else {
                    // 剩余的点
                    this.ctx.lineTo(x, y);
                }
                // 依次平移，绘制所有点
                x += sliceWidth;
            }

            this.ctx.lineTo(this.canvas.width, this.canvas.height / 2);
            this.ctx.stroke();
        },
    },
};
</script>
<style scoped>
.time>>>.el-progress-bar__outer {
    background-color: #ddd !important;
}

.Speaking>>>.el-button--primary {
    background-color: #de7d89;
    border-color: #de7d89;
}

.Speaking>>>.el-slider__button {
    border-color: #de7d89;
}
</style>
